@keyframes dropIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
  }
  
  .card-animation {
    opacity: 0;
    animation: fadeInUp 1s forwards;  }
  