.share-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid #ccc;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .share-icon:hover {
    background-color: #f0f0f0;
    border-color: #999;
  }
  