

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-20%);
  }
}

.animate-marquee {
  display: flex;
  animation: marquee 35s linear infinite;
  width: max-content;
}

